.List {
    display: grid;
    flex: 1;
    grid-auto-rows: max-content;
    box-sizing: border-box;
    grid-gap: 10px;
    padding: 0;
    margin: 0;
    border-radius: 5px;
    transition: background-color 350ms ease;
    grid-template-columns: repeat(var(--columns, 1), 1fr);
  
    &:after {
      content: '';
      height: 10px;
      grid-column-start: span var(--columns, 1);
    }
  
    &.horizontal {
      width: 100%;
      grid-auto-flow: column;
    }
  }